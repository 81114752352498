import React from 'react';

function SectionTokenomic(props) {
    return (
        <section className="section-5" style={{ backgroundImage: `url(img/bg5.jpg)` }}>
            <div className="container">
                <h2 className="page-title">tokenomics</h2>
                <img src="img/line_white.svg" alt="line" className="mx-auto d-block" />
                <img
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                    src="img/chart4.png"
                    alt="chart1"
                    className="mx-auto mt-40px d-block w-80"
                />
            </div>
        </section>
    );
}

export default SectionTokenomic;