import React from "react";

function ModalShowNike({ OnClose, Content }) {
  const contentList = [
    {
      img: "./img/modal/burn.svg",
      name: "burn",
      contents:
        "The higher the index, the less damaged the shoes are, and the less money it will cost to repair",
    },
    {
      img: "./img/modal/subtain.svg",
      name: "SUBTAIN",
      contents: `The higher the index, the easier it is to get excited when exercising (from Dopamine) from which players will have a lucky chance to receive "Happy box"`,
    },
    {
      img: "./img/modal/dopa.svg",
      name: "DOPA",
      contents: `The higher the index, the less damaged the shoes are, and the less money it will cost to repair`,
    },
    {
      img: "./img/modal/break.svg",
      name: "BREAK",
      contents: `Open stat limits the amount of Energy spawned in 1 day. (Newly joined the 
        game, players will not pay attention to this stat, but when running for a long 
        time and getting used to running at high frequency, players will be eager to 
        make money and want to run more to generate more Energy)`,
    },
  ];
  return (
    <div className="modal__show__nike__style">
      <h3>Nike color shoe</h3>
      <p>{Content}</p>
      <div className="price__nike">
        <p>
          <span>Current bid</span> 10 BNB
        </p>

        <img src="./img/modal/icon-bnb.png" alt="icon-bnb" />
      </div>
      <img
        className="close__modal"
        src="./img/modal/close-btn.svg"
        alt="close-btn"
        onClick={OnClose}
      />
      <div className="content__modal__nike">
        {contentList.map((nike, index) => (
          <div className="content__nike" key={index}>
            <div className="content__nike-img">
              <img src={nike.img} alt="img" />
              <span>{nike.name}</span>
            </div>
            <div className="content__nike-right">
              <p>{nike.contents}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModalShowNike;
