// https://react-slick.neostack.com/docs/example/multiple-items
import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

Slideshow.propTypes = {
  images: PropTypes.array,
};
Slideshow.defaultProps = {
  images: [],
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <img src={`img/btn_left.svg`} alt="" width="70" />
    </div>
  );
};
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <img src={`img/btn_right.svg`} alt="" width="70" />
    </div>
  );
};

function Slideshow({ images, onClick }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <Slider className="slideshow" {...settings}>
      {images.length > 0 &&
        images.map((image, index) => {
          return (
            <div className="slide" key={index} onClick={onClick}>
              <img src={`img/${image.name}`} alt={image.name} />
            </div>
          );
        })}
    </Slider>
  );
}

export default Slideshow;
