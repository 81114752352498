import React from "react";
import SlideshowComponent from "./SlideshowComponent";

function Section2(props) {
    return (
        <section
            className="section-2"
            style={{ backgroundImage: `url(img/bg2.jpg)` }}
        >
            {/* <img src="img/line-full-mobile.svg" alt="line-full-mobile" className="w-100" /> */}
            <div className="container">
                <h2 className="page-title">BURN TO EARN Benefits</h2>
                
                <div className="articles d-sm-block mt-24px">
                    <SlideshowComponent>
                        <article className="article article-1 odd">
                            <div className="article__content">
                                <div className="article__title">More burn more benefits</div>
                                <p className="article__desc">
                                    Every step you take in EMOVES is an opportunity to fill your
                                    pocket. Achievements from EMOVES can be used in a game world
                                    or simply as NFT rewards. From there, they can display or trade
                                    them.
                                </p>
                            </div>
                        </article>
                        <article className="article article-2 even">
                            <div className="article__content">
                                <div className="article__title">More burn more friends</div>
                                <p className="article__desc">
                                    Joining EMOVES challenges together is an effective way to make
                                    more friends, and increase engagement with everyone. Joining
                                    activities together helps create openness and friendliness
                                    between people, and help people understand each other better
                                </p>
                            </div>
                        </article>
                        <article className="article article-3 odd">
                            <div className="article__content">
                                <div className="article__title">More burn more health</div>
                                <p className="article__desc">
                                    Running greatly stimulates your heart rate and helps you burn
                                    calories especially effectively. The number of calories you burn
                                    in each minute of running will depend on your running speed and
                                    body weight.
                                </p>
                            </div>
                        </article>
                        <article className="article article-4 even">
                            <div className="article__content">
                                <div className="article__title">More burn more happiness</div>
                                <p className="article__desc">
                                    The effects of running with EMOVES not only help you improve
                                    your physical health, but also help you have an optimistic mind
                                    such as improving your mood, increasing energy and enhancing
                                    emotional well-being
                                </p>
                            </div>
                        </article>
                    </SlideshowComponent>
                </div>
            </div>
            <img
                data-aos="zoom-in"
                src="img/running_man.png"
                alt="running_man"
                className="running-main"
            />
        </section>
    );
}

export default Section2;
