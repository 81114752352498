// https://react-slick.neostack.com/docs/example/multiple-items
import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

SlideshowComponent.propTypes = {
    images: PropTypes.array
};
SlideshowComponent.defaultProps = {
    images: [],
}

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            <img
                src={`img/btn_left.svg`}
                alt=""
                width="70"
            />
        </div>
    );
}
const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            <img
                src={`img/btn_right.svg`}
                alt=""
                width="70"
            />
        </div>
    );
}

function SlideshowComponent({ children, mdSlideToShow=4, rows=1 }) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: mdSlideToShow,
        slidesToScroll: 1,
        rows: rows,
        slidesPerRow: rows,
        centerPadding: "56px",
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    dots: true,
                    infinite: true,
                    slidesToShow: 1,
                    arrows: false,
                    draggable: true
                }
            }
        ]
    };
    return (
        <Slider className="slideshow" {...settings}>
            {children}
        </Slider>
    );
}

export default SlideshowComponent;