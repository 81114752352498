import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { isMobile } from "react-device-detect";

function Header(props) {
  const headerList = [
    {
      href: "#home",
      name: "home",
    },
    {
      href: "#instruction",
      name: "instruction",
    },
    {
      href: "#nft",
      name: "nft",
    },
    {
      href: "#token",
      name: "token",
    },
    {
      href: "#roadmap",
      name: "roadmap",
    },
  ];

  const [active, setActive] = useState(0);

  const onClickActive = (e) => {
    setActive(e);
  };

  const [isShowAside, setIsShowAside] = useState(false);
  const account = "0x0515861897e5416Cfbb78CEeC389aa734E4e9d7f";
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const trimAccount = account
    ? `${account.slice(0, 6)}...${account.slice(35)}`
    : "0x00...0000"; // account length: 42
  return (
    <React.Fragment>
      <header className="header__style">
        <div className="section-header-top">
          <p className="address">
            <span className="hide__mobile">Contract Address:</span>
            <a
              href="https://bscscan.com/token/0x0515861897e5416Cfbb78CEeC389aa734E4e9d7f"
              target="_blank"
            >
              <span>EMV</span>
            </a>
          </p>{" "}
          <div
            className="account__contract"
            onClick={() => {
              if (navigator.clipboard) {
                navigator.clipboard.writeText(account);
                setIsTooltipDisplayed(true);
                setTimeout(() => {
                  setIsTooltipDisplayed(false);
                }, 1000);
              }
            }}
          >
            <span>{trimAccount}</span>
            <img src="./img/header/copy.svg" alt="" />
          </div>
          <div className="light"></div>
          <div className="audited">
            <a
              href="https://github.com/interfinetwork/smart-contract-audits/blob/audit-updates/EMOVES_0x0515861897e5416Cfbb78CEeC389aa734E4e9d7f.pdf"
              target="_blank"
              offset={() => 100}
            >
              <div className="verified hide__mobile">
                <span>verified</span>
              </div>
              <span className="hide__mobile">Audited by</span>
              <img src="./img/header/icon-audited.svg" alt="" />
              <span>InterFi</span>
            </a>
          </div>
        </div>
        <div className="section-header">
          <div className="container">
            <nav className="header">
              <div className="header__left">
                <img
                  src="/img/toggle-menu.svg"
                  alt="menu"
                  className="toggle-menu"
                  onClick={() => setIsShowAside(!isShowAside)}
                />
              </div>
              <AnchorLink href="#home" offset={() => 100} className="logo">
                <img src="img/logo.svg" alt="eMoves" />
              </AnchorLink>
              <div className="header__content">
                <ul className="header__menu">
                  {headerList.map((item, index) => (
                    <li
                      className={active === index ? " active" : ""}
                      key={index}
                      onClick={() => onClickActive(index)}
                    >
                      <AnchorLink href={item.href} offset={() => 100}>
                        {item.name}
                      </AnchorLink>
                    </li>
                  ))}
                  <li className="dropdown">
                    <div className="dropdown__header">
                      <span>Docs</span>
                      <i className="arrow"></i>
                    </div>
                    <ul className="dropdown__menu">
                      <li>
                        <a href="https://docs.emoves.io/" target="_blank">
                          Whitepaper
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="header__right"></div>
            </nav>
          </div>
        </div>
      </header>
      {isShowAside && (
        <aside className="aside-menu">
          <nav>
            <img
              src="/img/close-menu.svg"
              alt="menu"
              className="close-menu"
              onClick={() => setIsShowAside(!isShowAside)}
            />
            <AnchorLink href="#home" offset={() => 100}>
              home
            </AnchorLink>
            <AnchorLink href="#instruction">instruction</AnchorLink>
            <AnchorLink href="#nft">nft</AnchorLink>
            <AnchorLink href="#token">token</AnchorLink>
            <AnchorLink href="#roadmap">roadmap</AnchorLink>
            {isMobile ? "" : <li>docs</li>}
            <a href="https://docs.emoves.io/" target="_blank">
              white paper
            </a>
          </nav>
        </aside>
      )}
    </React.Fragment>
  );
}

export default Header;
