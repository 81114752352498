import React, { useState } from "react";
import GlobalModal from "./Common/GlobalModal";
import ModalShowNike from "./Modal/ModalShowNike";
import ModalVideo from "./Modal/ModalVideo";

import Slideshow from "./Slideshow";
import SlideshowComponent from "./SlideshowComponent";

SectionNFT.propTypes = {};

const imagesMid1 = [
  { name: "shoe_mid_1.2.png" },
  { name: "shoe_mid_1.1.png" },
  { name: "shoe_mid_1.3.png" },
];
const imagesMid2 = [
  { name: "shoe_mid_2.2.png" },
  { name: "shoe_mid_2.1.png" },
  { name: "shoe_mid_2.3.png" },
];
const imagesBurn1 = [
  { name: "shoe_burn_1.2.png" },
  { name: "shoe_burn_1.1.png" },
  { name: "shoe_burn_1.3.png" },
];
const imagesBurn2 = [
  { name: "shoe_burn_2.2.png" },
  { name: "shoe_burn_2.1.png" },
  { name: "shoe_burn_2.3.png" },
];
const imagesVipBurn = [
  { name: "shoe_vipburn_2.png" },
  { name: "shoe_vipburn_1.png" },
  { name: "shoe_vipburn_3.png" },
];

const ShowNikeList = [
  {
    name: "burn",
    img: "./img/modal/burn.svg",
    Bg: "linear-gradient(288.93deg, #FFB800 -19.91%, #FC0000 37.23%)",
  },
  {
    name: "dopa",
    img: "./img/modal/dopa.svg",
    Bg: "linear-gradient(180deg, #FF00A8 -1.36%, #FF00F5 115.53%)",
  },
  {
    name: "break",
    img: "./img/modal/break.svg",
    Bg: "linear-gradient(177.74deg, #63F3A6 -60.64%, #13C268 66.38%)",
  },
  {
    name: "sustain",
    img: "./img/modal/sustain.svg",
    Bg: "linear-gradient(180deg, #FF0000 0%, #0038FD 111.59%)",
  },
];

function SectionNFT(props) {
  const [tab, setTab] = useState("burn1");
  const [images, setImages] = useState(imagesBurn1);

  const ContentModalNikeList = [
    {
      contentTop:
        "Suitable for beginners with running, sedentary people, starting to build a new lifestyle.",
      contentBottom:
        "The higher the index, the less damaged the shoes are, and the less money it will cost to repair",
    },
    {
      contentTop:
        "Increase the difficulty, get used to running, improve your health",
      contentBottom:
        "Open stat limits the amount of Energy spawned in 1 day. (Newly joined the game, players will not pay attention to this stat, but when running for a long time and getting used to running at high frequency, players will be eager to make money and want to run more to generate more Energy)",
    },
    {
      contentTop:
        "Conquer your highest limits, suitable for those who have practiced for a long time",
      contentBottom: `The higher the index, the easier it is to get excited when exercising (from Dopamine) from which players will have a lucky chance to receive "Happy box"`,
    },
    {
      contentTop: "Challenge yourself, open the limit",
      contentBottom:
        "Only EMOVES increase when expending 1 energy - This stat should be the top priority when choosing shoes and upgrading",
    },
    {
      contentTop:
        "Almost suitable for all audiences, everyone can mobilize to create a new lifestyle",
      contentBottom:
        "Only EMOVES increase when expending 1 energy - This stat should be the top priority when choosing shoes and upgrading",
    },
  ];

  const ShoeContentList = [
    {
      contents:
        "Suitable for beginners with running, sedentary people, starting to build a new lifestyle.",
    },
    {
      contents:
        "Increase the difficulty, get used to running, improve your health.",
    },
    {
      contents:
        "Conquer your highest limits, suitable for those who have practiced for a long time.",
    },
    {
      contents: "Challenge yourself, open the limit.",
    },
    {
      contents:
        "Almost suitable for all audiences, everyone can mobilize to create a new lifestyle",
    },
  ];

  const handleTab = (name) => {
    setTab(name);
    switch (name) {
      case "mid1":
        setImages(imagesMid1);
        break;
      case "mid2":
        setImages(imagesMid2);
        break;
      case "burn2":
        setImages(imagesBurn2);
        break;
      case "vipburn":
        setImages(imagesVipBurn);
        break;
      default:
        setImages(imagesBurn1);
        break;
    }
  };

  const onClickShowModal = () => {
    GlobalModal.show(
      <ModalShowNike
        Content={
          tab === "mid1"
            ? ShoeContentList[0].contents
            : tab === "mid2"
              ? ShoeContentList[1].contents
              : tab === "burn2"
                ? ShoeContentList[3].contents
                : tab === "vipburn"
                  ? ShoeContentList[4].contents
                  : ShoeContentList[2].contents
        }
        OnClose={GlobalModal.hide}
      />
    );
  };
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const _linkYoutube = () => {
    GlobalModal.show(
      <ModalVideo OnClose={GlobalModal.hide}
      />
    );
    // window.open('https://www.youtube.com/watch?app=desktop&v=xfktjF-poGc', '_blank');
  }
  return (
    <section
      className="section-3"
      style={{ backgroundImage: `url(img/bg3.jpg)` }}
    >
      <img src="img/shoe_left.png" alt="shoe__left" className="shoe__left" />
      <img src="img/shoe_right.png" alt="shoe__right" className="shoe__right" />

      <div className="container" id="instruction">
        <h2 className="page-title text-primary">Instruction</h2>
        <img
          src="img/line.svg"
          alt="line"
          className="d-block mx-auto w-sm-70"
        />
        <div
          data-aos="zoom-in-right"
          className="video-container sm-w-70 mt-32px"
          onClick={() => _linkYoutube()}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "600px", borderRadius: "34px" }}
          >
            <source
              src={`img/thumbnail-trailer.${isSafari ? "mp4" : "webm"}`}
              type={`video/${isSafari ? "mp4" : "webm"}`}
            />
            <source src="img/thumbnail-trailer.mov" type="video/quicktime" />
          </video>
        </div>
        <div className="mt-56px">
          <div data-aos="fade-up" data-aos-duration="3000" className="">
            <div className="d-grid grid-col-2 d-sm-none g-56px mt-56px">
              <div className="text-right text-sm-center">
                <div className="text-primary fs-40px fs-sm-24px fw-800">
                  Build a healthy lifestyle
                </div>
                <p className="mt-8px">
                  Exercise is defined as any movement that makes your muscles
                  work and requires your body to burn EMOVES. Being active has
                  many health benefits, both physically and mentally. The
                  innovation of EMOVES is the motivation for users to do daily
                  exercise.
                </p>
              </div>
              <div className="text-sm-center">
                <div className="text-primary fs-40px fs-sm-24px fw-800">
                  Move to earn
                </div>
                <p className="mt-8px">
                  Let your workout activities be like a game. EMOVES allows
                  users to combine training and earning. The more EMOVES you
                  burn, the more income you get.
                </p>
              </div>
              <div className="text-right text-sm-center">
                <div className="text-primary fs-40px fs-sm-24px fw-800">
                  In the community
                </div>
                <p className="mt-8px">
                  Activities with friends can improve your mood and reduce
                  feelings of anxiety and depression. EMOVES builds crypto
                  metaverses where everyone can share together.
                </p>
              </div>
              <div className="text-sm-center">
                <div className="text-primary fs-40px fs-sm-24px fw-800">
                  More burn more earn
                </div>
                <p className="mt-8px">
                  <b>EMOVES</b> is a calo app with Social – Fi and Game – Fi
                  element. The more EMOVES users burn, the more token they gain.
                  With <b>EMOVES</b>, users can both join happy health
                  challenges and get more income.
                </p>
              </div>
            </div>
            <div className="d-none d-sm-block">
              <SlideshowComponent>
                <div className="text-right text-sm-center">
                  <div className="text-primary fs-40px fs-sm-24px fw-800">
                    Build a healthy lifestyle
                  </div>
                  <p className="mt-8px">
                    Exercise is defined as any movement that makes your muscles
                    work and requires your body to burn EMOVES. Being active has
                    many health benefits, both physically and mentally. The
                    innovation of EMOVES is the motivation for users to do daily
                    exercise.
                  </p>
                </div>
                <div className="text-sm-center">
                  <div className="text-primary fs-40px fs-sm-24px fw-800">
                    Move to earn
                  </div>
                  <p className="mt-8px">
                    Let your workout activities be like a game. EMOVES allows
                    users to combine training and earning. The more EMOVES you
                    burn, the more income you get.
                  </p>
                </div>
                <div className="text-right text-sm-center">
                  <div className="text-primary fs-40px fs-sm-24px fw-800">
                    In the community
                  </div>
                  <p className="mt-8px">
                    Activities with friends can improve your mood and reduce
                    feelings of anxiety and depression. EMOVES builds crypto
                    metaverses where everyone can share together.
                  </p>
                </div>
                <div className="text-sm-center">
                  <div className="text-primary fs-40px fs-sm-24px fw-800">
                    More burn more earn
                  </div>
                  <p className="mt-8px">
                    <b>EMOVES</b> is a calo app with Social – Fi and Game – Fi
                    element. The more EMOVES users burn, the more token they
                    gain. With <b>EMOVES</b>, users can both join happy health
                    challenges and get more income.
                  </p>
                </div>
              </SlideshowComponent>
            </div>
          </div>
        </div>
      </div>

      <img src="img/line2.svg" alt="line2" className="d-block mt-sm-32px" />

      <div className="container mt-sm-32px" id="nft">
        <h2 className="page-title">nft</h2>
        <div className="tab-container">
          <div className="tab">
            <span
              className={`tab__item pointer ${tab === "mid1" ? "active" : ""}`}
              onClick={() => handleTab("mid1")}
            >
              mid 1
            </span>
            <span
              className={`tab__item pointer ${tab === "mid2" ? "active" : ""}`}
              onClick={() => handleTab("mid2")}
            >
              mid 2
            </span>
            <span
              className={`tab__item pointer ${tab === "burn1" ? "active" : ""}`}
              onClick={() => handleTab("burn1")}
            >
              burn 1
            </span>
            <span
              className={`tab__item pointer ${tab === "burn2" ? "active" : ""}`}
              onClick={() => handleTab("burn2")}
            >
              burn 2
            </span>
            <span
              className={`tab__item pointer ${tab === "vipburn" ? "active" : ""
                }`}
              onClick={() => handleTab("vipburn")}
            >
              vipburn
            </span>
          </div>
        </div>
        <div className="mt-sm-32px" style={{ paddingTop: "30px" }}>
          <Slideshow images={images} onClick={() => onClickShowModal()} />
        </div>
        <div className="d-sm-none">
          {/* 
          <div className="show__modal">
            {ShowNikeList.map((nike, index) => (
              <div
                key={index}
                className="show__modal__content"
                style={{ background: `${nike.Bg}` }}
                onClick={() =>
                  GlobalModal.show(
                    <ModalNike
                      ContentTop={
                        tab === "mid1"
                          ? ContentModalNikeList[0].contentTop
                          : tab === "mid2"
                          ? ContentModalNikeList[1].contentTop
                          : tab === "burn1"
                          ? ContentModalNikeList[2].contentTop
                          : tab === "burn2"
                          ? ContentModalNikeList[3].contentTop
                          : ContentModalNikeList[4].contentTop
                      }
                      ContentBottom={
                        tab === "mid1"
                          ? ContentModalNikeList[0].contentBottom
                          : tab === "mid2"
                          ? ContentModalNikeList[1].contentBottom
                          : tab === "burn1"
                          ? ContentModalNikeList[2].contentBottom
                          : tab === "burn2"
                          ? ContentModalNikeList[3].contentBottom
                          : ContentModalNikeList[4].contentBottom
                      }
                      NameNike={nike.name}
                      OnClose={GlobalModal.hide}
                    />
                  )
                }
              >
                <img src={nike.img} alt="img" />
                <span>{nike.name}</span>
              </div>
            ))}
          </div>
        */}
        </div>
      </div>
    </section>
  );
}

export default SectionNFT;
