import React from "react";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="d-flex mr-40px">
            <img src="img/logo.svg" alt="logo" className="logo" />
          </div>
          <div className="flex-1">
            <h3 className="footer__title text-primary">About Emoves</h3>
            <nav className="footer__nav">
              <a href="#play-game">play game</a>
              <a href="#marketPlace">marketPlace</a>
              <a href="#docs">Docs</a>
              <a href="#nft-items">NFT Items</a>
              <a href="#support">Support</a>
            </nav>
          </div>
          <div className="flex-1">
            <h3 className="footer__title text-primary">Community</h3>
            <nav className="footer__nav">
              <a href="https://www.facebook.com/emovesofficial">
                <img src="img/facebook.svg" alt="facebook" className="mr-8px" />{" "}
                Facebook Fanpage
              </a>
              <a href="https://t.me/EmovesANN">
                <img src="img/telegram.svg" alt="telegram" className="mr-8px" />{" "}
                Telegram official
              </a>
              <a href="https://www.youtube.com/channel/UCb5DyC9PyxiENqLykhlvaFw">
                <img src="img/youtube.svg" alt="youtube" className="mr-8px" />{" "}
                Youtube chanel
              </a>
              <a href="https://medium.com/@emoves">
                <img
                  src="img/footer/medium.svg"
                  alt="medium"
                  className="mr-8px"
                />{" "}
                Medium
              </a>
              <a href="https://linktr.ee/emoveslink">
                <img
                  src="img/footer/linktree.svg"
                  alt="linktree"
                  className="mr-8px"
                />{" "}
                LinkTree
              </a>
            </nav>
          </div>
          <div className="flex-1 sm-d-none">
            <h3 className="footer__title text-primary">Download</h3>
            <div className="mt-24px">
              <img
                src="img/app_store.png"
                alt=""
                width="250px"
                className="d-block"
              />
              <a target="_blank" href="https://drive.google.com/file/d/1vEhkUxT5UAz9NlgU39vS7Q365DMIzK76/view?usp=sharing">
                <img
                  src="img/google_play.png"
                  alt=""
                  width="250px"
                  className="d-block mt-24px"
                />
              </a>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
