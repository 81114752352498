import React from "react";
import { isMobile } from 'react-device-detect'

function ModalShowNike({ OnClose, Content }) {

  return (
    <div className="">
      <iframe width={`${isMobile ? '' : '1130'}`} height={`${isMobile ? '' : '636'}`} src="https://www.youtube.com/embed/xfktjF-poGc?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
}

export default ModalShowNike;
