import React from "react";

function SectionToken(props) {
    return (
        <section
            className="section-4"
            style={{ backgroundImage: `url(img/bg4.jpg)` }}
        >
            <div className="container" id="token">
                <h2 className="page-title">token</h2>
                <img src="img/line_white.svg" alt="line" className="d-block mx-auto w-sm-70" />
                <div className="d-flex sm-flex-col mt-40px">
                    <div
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        className="flex-2 mt-80px mt-sm-0 sm-order-2"
                    >
                        <div className="bg-white rounded-20px py-16px px-24px sm-py-8px sm-px-16px">
                            <h3 className="text-primary fw-800 fs-40px fs-sm-20px">EMV Token</h3>
                            <p className="text-primary fs-24px fs-sm-12px">
                                The main currency with limited supply for financial purposes{" "}
                            </p>
                        </div>
                        <div className="mt-40px sm-mt-16px">
                            <div className="badge">
                                <img src="img/shield.svg" alt="shield" className="mr-8px" />
                                Governance
                            </div>
                            <p
                                data-aos-easing="ease-in-sine"
                                className="fw-500 fs-24px mt-8px fs-sm-12px"
                            >
                                Stake to participate in governance, the longer the locking
                                period, the higher the voting power.
                            </p>
                        </div>
                        <div className="mt-40px sm-mt-16px">
                            <div className="badge">
                                <img src="img/cup.svg" alt="cup" className="mr-8px" />
                                Staking reward
                            </div>
                            <p className="fw-500 fs-24px mt-8px fs-sm-12px">
                                EMV stakers can vote to decide the distribution of staking
                                rewards.
                            </p>
                        </div>
                        <div className="mt-40px sm-mt-16px">
                            <div className="badge">
                                <img src="img/pig.svg" alt="pig.svg" className="mr-8px" />
                                Accrual of protocol revenue
                            </div>
                            <p className="fw-500 fs-24px mt-8px fs-sm-12px">
                                EMV is burned to access in-app features, such as sneaker
                                upgrades and minting of new sneakers.
                            </p>
                        </div>
                    </div>
                    <div className="flex-1 sm-order-1">
                        <img src="img/icon_token.png" alt="icon token" />
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    className="d-flex flex-right sm-flex-col mt-96px mt-sm-32px"
                >
                    <div className="mr-32px mr-sm-0">
                        <img src="img/shoe_gold.png" alt="shoe_gold" width={400}/>
                    </div>
                    <div className="flex-2 mt-sm-32px">
                        <div className="tr sm-text-left bg-white rounded-20px py-16px px-24px sm-py-8px sm-px-16px">
                            <h3 className="text-primary fw-800 fs-40px fs-sm-20px">
                                EMV token is used for gaming purposes
                            </h3>
                        </div>
                        <div
                            data-aos="fade-left"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine"
                            className="flex-2"
                        >
                            <div className="d-flex sm-flex-col flex-right mt-72px mt-sm-16px">
                                <div className="flex-1 tr">
                                    <div className="badge w-100">
                                        <img
                                            src="img/refresh.svg"
                                            alt="refresh.svg"
                                            className="mr-8px"
                                            height="24px"
                                        />
                                        Upgrade sneaker
                                    </div>
                                    <div className="badge w-100 mt-24px mt-sm-16px">
                                        <img src="img/fix.svg" alt="fix.svg" height="24px" className="mr-8px" />
                                        Sneaker Repair
                                    </div>
                                    <div className="badge w-100 mt-24px mt-sm-16px">
                                        <img src="img/man.svg" alt="man.svg" height="24px" className="mr-8px" />{" "}
                                        Join challenges
                                    </div>
                                </div>
                                <div className="flex-1 tr ml-24px ml-sm-0 mt-sm-16px">
                                    <div className="badge w-100">
                                        <img
                                            src="img/diamond.svg"
                                            alt="diamond.svg"
                                            className="mr-8px"
                                            height="24px"
                                        />
                                        Gem upgrade
                                    </div>
                                    <div className="badge w-100 mt-24px mt-sm-16px">
                                        <img
                                            src="img/unclock.svg"
                                            alt="unclock.svg"
                                            className="mr-8px"
                                            height="24px"
                                        />
                                        Unlock Socket
                                    </div>
                                    <div className="badge w-100 mt-24px mt-sm-16px">
                                        <img src="img/feet.svg" alt="feet.svg" height="24px"  className="mr-8px" />
                                        Calo Vault
                                    </div>
                                </div>
                            </div>
                            <div className="badge sm-w-100 mt-24px mt-sm-16px">
                                <img src="img/spiner.svg" height="24px" alt="spiner.svg" className="mr-8px" />
                                Reset Sneaker properties
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionToken;
