import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

Carousel.propTypes = {
  images: PropTypes.array,
};
Carousel.defaultProps = {
  images: [],
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <img src={`img/btn_left.svg`} alt="" width="70" />
    </div>
  );
};
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <img src={`img/btn_right.svg`} alt="" width="70" />
    </div>
  );
};

function Carousel({ className }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          customPaging: "16px",
        },
      },
    ],
  };

  const TeamList = [
    {
      name: "Nam Thanh Truong",
      position: (
        <>
          <p>CEO & CO-FOUNDER</p> <p>& CPO (Chief Product Officer)</p>
        </>
      ),
      img: "./img/teams/nam.png",
      linkedin: "https://www.linkedin.com/in/nam-truong-thanh-1a445b72",
    },
    {
      name: "Thao Dinh",
      position: "CTO",
      img: "./img/teams/dinh.png",
      linkedin: "https://www.linkedin.com/in/dthaoit/",
    },
    {
      name: "Rosie Nguyen",
      position: "CMO",
      img: "./img/teams/rosie.png",
      linkedin: "https://www.linkedin.com/in/phuongnhung/",
    },
    {
      name: "Alice Nguyen",
      position: "CSO",
      img: "./img/teams/alice.png",
      linkedin: "https://www.linkedin.com/in/alice-nguyen-aba71620b/",
    },
    {
      name: "Krystal Pham",
      position: "Community Manager",
      img: "./img/teams/krystal.png",
      linkedin: "http://www.linkedin.com/in/nganpham10",
    },
    {
      name: "Erina Le",
      position: "Content Manager",
      img: "./img/teams/erina.png",
      linkedin: "https://www.linkedin.com/in/erinale/",
    },
    {
      name: "BravisTa",
      position: "Head of Creative",
      img: "./img/teams/bravista.png",
      linkedin: "http://linkedin.com/in/bravista",
    },
    {
      name: "Tomy Khang",
      position: "Lead Designer",
      img: "./img/teams/khang.png",
      linkedin: "https://www.linkedin.com/in/khang-tomy-51934a238/",
    },
    {
      name: "David  Phuc",
      position: "Game Designer",
      img: "./img/teams/phuc.png",
      linkedin: "https://www.linkedin.com/in/phuc-david-472354238/",
    },
    {
      name: "Kevin Nhan",
      position: "UX/ UI Designer",
      img: "./img/teams/nhan.png",
      linkedin: "https://www.linkedin.com/in/kevin-nhan-149513238/",
    },
    {
      name: "Steven Ao",
      position: (
        <>
          <p>Senior Blockchain</p> <p>& Game Developer</p>
        </>
      ),
      img: "./img/teams/steven.png",
      linkedin: "https://www.linkedin.com/in/ao-steven-912353238/",
    },
  ];
  return (
    <Slider className={`carousel ${className}`} {...settings}>
      {TeamList.map((team, index) => (
        <div className="slide" key={index}>
          <img src={team.img} alt="member1" />
          <div className="fw-900 tc mt-16px sm-mt-8px sm-fs-12px">
            {team.name}
          </div>
          <div className="tc mt-8px">
            <div className="chip">
              {" "}
              <span>{team.position}</span>
              <a href={team.linkedin} target="_blank">
                {" "}
                <img src="./img/teams/icon-in.svg" alt="icon-in" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default Carousel;
