import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import GlobalModal from "./components/Common/GlobalModal";
import Footer from "./components/Footer";
import Mobile from "./components/Mobile";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import SectionBacker from "./components/SectionBacker";
import SectionHero from "./components/SectionHero";
import SectionNFT from "./components/SectionNFT";
import SectionRoadmap from "./components/SectionRoadmap";
import SectionAdvisors from "./components/SectionAdvisors";
import SectionTeam from "./components/SectionTeam";
import SectionToken from "./components/SectionToken";
import SectionTokenomic from "./components/SectionTokenomic";
import styled from "styled-components";

const App = () => {
  useEffect(() => {
    AOS.init({
      disable: window.innerWidth < 768,
      duration: 1200,
    });
  }, []);

  return (
    <div className="" style={{ overflow: "hidden" }}>
      <GlobalModal />
      <Header />
      <SectionHero />
      <Section1 />
      <Section2 />
      <SectionNFT />
      <SectionToken />
      <SectionTokenomic />
      <SectionRoadmap />
      {/* <SectionTeam /> */}
      {/* <SectionAdvisors /> */}
      <SectionBacker />
      {<Footer />}
    </div>
  );
};

export default App;
