import React from "react";

function SectionHero(props) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <section className="section-hero" id="home">
      <video loop="true" autoplay="autoplay" muted>
        <source
          src={`video/comp-nike.${isSafari ? "mp4" : "webm"}`}
          type={`video/${isSafari ? "mp4" : "webm"}`}
        />
        <source src="video/comp-nike.mov" type="video/quicktime" />
      </video>
    </section>
  );
}

export default SectionHero;
