import React from "react";

function SectionRoadmap(props) {
  return (
    <section
      className="section-6"
      style={{ backgroundImage: `url(img/bg6.jpg)` }}
    >
      <div className="container" id="roadmap">
        <h2 className="page-title">roadmap</h2>
        <img
          src="img/line_white.svg"
          alt="line"
          className="mx-auto d-block sm-w-70"
        />
        <img
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          src="img/chart3-roadmap.png"
          alt="chart2"
          className="mx-auto mt-40px d-block w-80"
        />
      </div>
    </section>
  );
}

export default SectionRoadmap;
