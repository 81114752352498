import Slider from "react-slick";

const listImgLogo = [
  {
    img: "bizverse-final",
    src: ''
  },
  {
    img: "nav-bar",
    src: ''
  },
  {
    img: "meta",
    src: ''
  },
  {
    img: "logo-bestay",
    src: ''
  },
  {
    img: "pinksale",
    src: "https://pinksale.finance/"
  },
  {
    img: "dao-starter",
    src: ''
  },
  {
    img: "mars-pad",
    src: ''
  },
  {
    img: "gem-pad",
    src: ''
  },
  {
    img: "acy",
    src: ''
  },
];
function SectionBacker(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section
      className="section-8"
      style={{ backgroundImage: `url(img/bg7.jpg)` }}
    >
      <div className="container">
        <h2 className="page-title">Partner</h2>
        <img
          src="img/line_white.svg"
          alt="line"
          className="mx-auto d-block sm-w-70"
        />

        <Slider className="slideshow" {...settings}>
          {listImgLogo.map((item, index) => (
            <div key={index}>
              <a target="_blank" href={item.src}>
                <img
                  style={
                    item.img === "pinksale"
                      ? { width: "138px", height: "138px" }
                      : null
                  }
                  src={`img/backer/${item.img}.png`}
                  alt="backer"
                />
              </a>

            </div>
          ))}
        </Slider>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="d-flex flex-middle flex-content-between mt-96px sm-mt-40px"
        ></div>
      </div>
    </section>
  );
}

export default SectionBacker;
