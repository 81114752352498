import React from "react";

function Section1(props) {
  return (
    <section
      className="section-1"
      style={{ backgroundImage: `url(img/bg1.jpg)` }}
    >
      <div className="container">
        <div className="d-flex d-flex-col-sm flex-middle flex-content-between">
          <div
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
            className="flex-1 order-sm-2 mt-sm-32px"
          >
            <div className="fs-24px fs-sm-16px fw-600 ttu">
              the emoves metaverse
            </div>
            <div className="fs-48px  fw-800 ttu mt-8px fs-sm-24px lh-sm-125">
              official Launching in may 2022
            </div>
            <p className="desc mt-8px">
              Running greatly stimulates your heart rate and helps you burn
              calories especially effectively. The number of calories you burn
              in each minute of running will depend on your running speed and
              body weight.
            </p>
            <div className="cta mt-48px mt-sm-32px">
              <button type="button" className="btn ttu">
                join with us
              </button>
            </div>
            <div className="d-flex flex-center mt-64px mt-sm-32px">
              <a href="#app-store">
                <img src="img/app_store.png" alt="app_store" height="56px" />
              </a>

              <a
                target="_blank"
                href="https://drive.google.com/file/d/1vEhkUxT5UAz9NlgU39vS7Q365DMIzK76/view?usp=sharing"
                className="ml-8px"
              >
                <img
                  src="img/google_play.png"
                  alt="google_play"
                  height="56px"
                />
              </a>
            </div>
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
            className="ml-56px ml-sm-0 order-sm-1"
          >
            <img src="img/metaverse.png" alt="metaverse" width="500px" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section1;
